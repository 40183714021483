import axios from "axios";

export default {
  namespaced: true,
  state: {
    scoreData: {},
    previousAnswer:{},
    scorePageShareLink:{}
  },

  mutations: {
    FinalAnswerScore(state, data) {
      state.scoreData = data;
    },
    answerGivenByUser(state,data){
      state.previousAnswer = data;
    },
    shareLink(state,data){
      state.scorePageShareLink = data;
    }
  },

  actions: {
    async getScoreData({ commit }, data) {
      try {
        return await axios
          .get(`${process.env.VUE_APP_API_URL}/game/share-score/${data}`)
          .then((res) => {
            commit("FinalAnswerScore", res.data);
            return true;
          })
          .catch((res) => {
            console.log("backend", res);
          });
      } catch (error) {
        console.log("error");
        console.error("Error fetching generation data:", error);
      }
    },

    async getGivenAnswerData(
       /* eslint-disable */
      { commit },
       data
       ) {
      try {
        console.log(data);
         await axios
          .get(`${process.env.VUE_APP_API_URL}/game/share-comparative-score/${data.game_played_id}/${data.game_id}/${data.questionId}`)
          .then((res) => {
            console.log(res.data);
            commit("answerGivenByUser", res.data);
            
          })
          .catch((res) => {
            console.log("backend", res);
          });
      } catch (error) {
        console.log("error");
        console.error("Error fetching generation data:", error);
      }
    },

    async scoreImageSaved(
      /* eslint-disable */
       { commit },
       payload,
      //   {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //   }
      // }
       ) {

     try {
          return  axios
                  .post(`${process.env.VUE_APP_API_URL}/user/score-image`,payload)
                  .then((res) => {
                     console.log("scoreImageSaved",res);
                     if(res.status === 200 && res.data.success === true) {
                      commit("shareLink", res.data.data);
                     }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
          } catch (error) {
                console.error("Error fetching generation data:", error);
          }    
   },
  },
};
