import axiosInstance from "@/axiosInstance";

export default {
  namespaced: true,
  state: {
    details:""
  },

  mutations: {
    userInfo(state, data){
      state.details = data
    }
  },

  actions: {
   async userDetails(
    /* eslint-disable */
    { commit }
   ) {
      try {
        return axiosInstance
          .get("user/edit-profile")
          .then((res) => {
            console.log("editProfile",res);
            if(res.data.code === 200 && res.data.status.success === true) {
              commit("userInfo", res.data.data.user); 
            }
          })
          .catch((res) => {
            console.log(res);
          });
      } catch (error) {
        console.error("Error fetching generation data:", error);
      }
    },

    async userEditProfile(
      /* eslint-disable */
      { commit },
      payload
    ) {
      try {
        return axiosInstance
          .post("user/profile-update", payload)
          .then((res) => {
            let existingUserDetails = JSON.parse(
              localStorage.getItem("userDetails")
            );

            if (existingUserDetails) {
              let userData = {
                token: existingUserDetails.token,
                userDetails: res.data.data.user,
              };
              // Remove the old userDetails from local storage
              localStorage.removeItem("userDetails");

              // Store the updated userDetails back into local storage
              localStorage.setItem("userDetails", JSON.stringify(userData));
              return true;
            }
          })
          .catch((res) => {
            console.log(res);
          });
      } catch (error) {
        console.error("Error fetching generation data:", error);
      }
    },

    async changePassword(
      /* eslint-disable */
      { commit },payload
    ) {
      try {
        return axiosInstance
          .post("/user/change-password",payload)
          .then((res) => {
            return res.data;
          })
          .catch((res) => {
            console.log(res.response.data.message);
            return {
              success:false,
              message:res.response.data.message
            }
          });
      } catch (error) {
        console.error("Error fetching generation data:", error);
      }
    },
  },
};
