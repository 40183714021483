import Vue from "vue";
import Vuex from "vuex";

import defaultModule from './modules/default.js';
import quizModule from "./modules/quiz.js";
import infoModule from "./modules/info.js";
import previousModule from "./modules/previousQuiz.js";
import shareModule from "./modules/share.js";
import authModule from "./modules/auth.js";
import userProfile from "./modules/userProfile.js";
import userDataChart from "./modules/userDatachart.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    default:defaultModule,
    quiz:quizModule,
    info:infoModule,
    previous:previousModule,
    shareModule:shareModule,
    authModule:authModule,
    profileModule:userProfile,
    chartModule:userDataChart
  },
});
