
import axiosInstance from '@/axiosInstance';

export default {
  namespaced: true,
  state: {
    profileChartDetails:{}
  },

  mutations: {
    userProfileChart(state,data){
        state.profileChartDetails = data;
    }
  },

  actions: {
      async chartDeatils(
        /* eslint-disable */
         { commit },
         payload
         ) {
            console.log("payload",payload);
              try {
                return  axiosInstance
                  .get(`/user/score-analysis?period=${payload}`)
                  .then((res) => {                  
                    if(res?.data?.success === true) {
                      commit("userProfileChart", res?.data?.data);
                    }
                  })
                  .catch((res) => {
                    console.log(res);
                  });
              } catch (error) {
                console.error("Error fetching generation data:", error);
              }     
        },

  },
};
